import Ad from "src/components/ads/Ad";
import AdUnitWithPosition from "src/components/forsiden/AdUnitWithPosition";
import ArticleRowBlock24 from "src/components/forsiden/ArticleRowBlock2-4";
import ArticleRowBlockV2 from "src/components/forsiden/ArticleRowBlockV2";
import ArticlesAndList from "src/components/forsiden/ArticlesAndList";
import PosterRowWithTitleV2 from "src/components/forsiden/PosterRowWithTitleV2";
import SquareWideAdBLockV2 from "src/components/forsiden/SquareWideAdBLockV2";
import TopBlockV2 from "src/components/forsiden/TopBlockV2";
import VerticalArticleListBlock from "src/components/forsiden/VerticalArticleListBlock";
import StreamingSearchBox from "src/components/streaming/StreamingSearchBox";
import { ICommonAppData } from "src/lib/types/ICommonAppData";
import { CountableItemTypes, ItemType_ArticleRowBlock, ItemType_ArticleRowBlock24, ItemType_ArticlesAndList, ItemType_BlockWithAd, ItemType_ListPosterRow, ItemType_PosterList, ItemType_SquareArticleRowBlock, ItemType_StreamingSearchBox, ItemType_TopBlock, ItemType_VerticalArticleListGroup, LayoutObject } from "src/lib/types/IForsidenV2";
import React from "react";

//#region [Props]
type ArticleBlockProps = {
    items: LayoutObject[];
    children: AdUnitWithPosition[];
    commonAppData: ICommonAppData;
};

export type CustomBlockProps = {
    streamingSearchBox?: {
        showLogo?: boolean;
    }
}
//#endregion

//#region [Component]
export default function ArticleBlockV2({ items, children, commonAppData }: ArticleBlockProps) {
    if (items.length === 0) {
        return null;
    }
    let counter = 0;
    let adCounter = 0;
    let lastContentType = "";
    const childrenWithPos = children.filter( ad => ad.position !== undefined)
    let articleItems = items.map((item) => {
        let elem: React.ReactNode;

        switch (item._type) {

            case ItemType_TopBlock:
                // TopBlock handles it's own ads due to layout control for desktop/mobile
                elem = <TopBlockV2 key={item._key} block={item} />;
                lastContentType = item._type;
                break;
            case ItemType_StreamingSearchBox:
                elem = <StreamingSearchBox key={item._key} toolbox={commonAppData.streamingToolbox} showLogo={item.showSgLogo} />
                lastContentType = item._type;
                break;
            case ItemType_PosterList:
                elem = <PosterRowWithTitleV2 key={item._key} posterRow={item} />
                lastContentType = item._type;
                break;
            case ItemType_BlockWithAd:
                elem = <SquareWideAdBLockV2 key={item._key} block={item} />
                lastContentType = item._type;
                break;
            case ItemType_ArticleRowBlock:
                elem = <ArticleRowBlockV2 key={item._key} block={item} />
                lastContentType = item._type;
                break;
            case ItemType_ArticleRowBlock24:
                elem = <ArticleRowBlock24 key={item._key} block={item} />
                lastContentType = item._type;
                break;
            case ItemType_SquareArticleRowBlock:
                elem = <ArticleRowBlockV2 key={item._key} block={item} isSquare />
                lastContentType = item._type;
                break;
            case ItemType_ListPosterRow:
                elem = <PosterRowWithTitleV2 key={item._key} posterRow={item} />
                lastContentType = item._type;
                break;
            case ItemType_VerticalArticleListGroup:
                elem = <VerticalArticleListBlock key={item._key} block={item} />
                lastContentType = item._type;
                break;
            case ItemType_ArticlesAndList:
                elem = <ArticlesAndList key={item._key} block={item} />
                lastContentType = "";
                break;
            default:
                lastContentType = "";
                return null;
        }

        if (childrenWithPos[adCounter]?.position !== undefined && childrenWithPos[adCounter]?.position === counter) {
            elem = <React.Fragment key={`${adCounter}_${item._key}`}>
                <Ad targetId={childrenWithPos[adCounter].targetId!} height="300" />
                {elem}
            </React.Fragment>
            adCounter++;
        }
        counter++;
        return elem;
    });

    if (adCounter < children.length) {
        for (let c = adCounter; c < children.length; c++) {
            if (children[c]?.showAfterContentIf?.(counter, c + 1 === children.length)) {
                articleItems = [...articleItems, <Ad key={`ad_${c}`} targetId={children[c].targetId!} height="300" />];
            }
        }
    }

    return articleItems;
}
//#endregion
