"use client"
import LoadingIndicator from "src/components/loadingindicator/LoadingIndicator";
import PosterRow from "src/components/posterrow/PosterRow";
import { gql } from "graphql-request";
import { useFilminfo } from "src/lib/client/useFilminfo";
import { StreamingContentType } from "src/lib/movieinfo/movieinfotypes";
import IMovieList from "src/lib/types/MovieList";

//#region [Props]
type StreamingPosterRowWithSelfLoadProps = {
	movieList: IMovieList;
	className?: string;
	max?: number;

};
//#endregion

//#region [Component]
export default function StreamingPosterRowWithSelfLoad({ movieList, className, max }: StreamingPosterRowWithSelfLoadProps) {
	const streamingIds = movieList.streamingIds;
	const result = useFilminfo(movieListPosterQuery, { streamingIds });
	if (result.fiLoading) {
		return <LoadingIndicator position="InlineCenter" size="large" />
	}
	if ((result.fiData?.streamingQuery?.getStreamingContentList?.length ?? 0) === 0) {
		return null;
	}
	return <PosterRow max={max} className={className} posterList={result.fiData!.streamingQuery!.getStreamingContentList as StreamingContentType[]} />;
}
//#endregion


export const movieListPosterQuery = gql`
query ($streamingIds: [Int]!) {
	streamingQuery {
		getStreamingContentList(ids: $streamingIds) {
			__typename
			id
			isSeries
			filmwebMovieId
			title
			imagesPoster {
				width
				height
				url
			}
			sanityImagePosterUrl
		}
	}
}
`;