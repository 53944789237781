import DocumentIcon from "src/components/documenticon/DocumentIcon";
import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import CmsImage from "src/components/images/CmsImage";
import CSSAdContentLabel, { CSSAdContentLabelAfter } from "src/components/styles/CSSAdContentLabel";
import theme from "src/components/themes/DefaultTheme";
import styled, { css } from 'src/lib/styles/css';
import IFilmnytt from "src/lib/types/Filmnytt";
import { ThemeValues } from "src/lib/types/IForsidenV2";
import { getImage, getUrlForDoctype } from "src/lib/types/sanity";

//#region [Props]
type SquareImageLeftTitleProps = {
    document: IFilmnytt;
    isStreamingContext?: boolean;
    themeValue?: ThemeValues;
};
//#endregion

//#region [Component]
export default function SquareImageLeftTitle({ document, isStreamingContext, themeValue }: SquareImageLeftTitleProps) {
    const slug: string = document.slug?.current;
    const url = getUrlForDoctype(document._type, slug, document.isStreaming as boolean | undefined ?? isStreamingContext, document);
    const image = getImage(document);

    return <article>
        <SFilmwebLink to={url!} $themeValue={themeValue} $isContentAd={document.isAdContent}>
            {image && <SImgContainer $isContentAd={document.isAdContent} $themeValue={themeValue}>
                <SCmsImage image={image} alt={image.asset?.altText ?? ""} width={128} crop applyAspectRatio nonPosterAspect={1} />
                <SDocumentIcon document={document} />
            </SImgContainer>}
            <STitleContainer $isContentAd={document.isAdContent}>
                <STitle>{document.title}</STitle>
            </STitleContainer>

        </SFilmwebLink>
    </article>;
}
//#endregion

//#region [Styles]
type SSquareImageLeftTitleProps = {
    $themeValue?: ThemeValues;
    $isContentAd?: boolean;
}

function getThemeValueColor(th?: ThemeValues): string {
    switch (th) {
        case "gold":
        case "light":
        case "lightGradient":
            return "var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor))";
        default:
            return "var(--TEXTCOLOR_OVERRIDE, var(--textcolor))";
    }
}

const SFilmwebLink = styled(FilmwebLink) <SSquareImageLeftTitleProps>`
    color: ${props => getThemeValueColor(props.$themeValue)};

	text-decoration: none;
    gap: 20px;

	display: flex;
    align-items: flex-start;

	&:hover {
		text-decoration: underline;
	}

	${props => props.$isContentAd ? css`
		color: #8fddfd;

		${(props.$themeValue ?? "dark") !== "dark" ? css`
			color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
		`: ""}

	`: ""}
`;

const SImgContainer = styled.div<SSquareImageLeftTitleProps>`
    border-radius: 8px;
    overflow: hidden;
    flex: 0 0 94px;
    aspect-ratio: 1 / 1;
    position: relative;

    @media ${theme.mq.desktop} {
        flex: 0 0 128px;

        ${props => props.$isContentAd ? css`
            ${CSSAdContentLabel}

            &::before {

                font-size: 9px;
                padding: 5px 5px;
                right: 50%;
                transform: translateX(50%);
                top: 5px;

                @media ${theme.mq.desktop} {
                    font-size: 9px;
                    padding: 5px 8px;
                    right: 5px;
                    top: 5px;
                    transform: none;
                }
            }

            ${(props.$themeValue ?? "dark") !== "dark" ? css`
                &::before {
                    color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
                    background-color: #8fddfd;
                }
            `: ""}

        `: ""}
    }

`;

const SCmsImage = styled(CmsImage)`
	object-fit: contain;
    display: block;
`;

const STitleContainer = styled.div<SSquareImageLeftTitleProps>`
    flex: 1 1 auto;

    ${props => props.$isContentAd ? css`
        @media ${theme.mq.mobile} {
            ${CSSAdContentLabelAfter}

        }
    `:""}
`;

const STitle = styled.h1`

    font-size: ${theme.fonts.tittelXXS.size};
    @media ${theme.mq.desktop} {
        font-size: ${theme.fonts.tittelXS.size};
    }
`;

const SDocumentIcon = styled(DocumentIcon)`
    top: auto;
    bottom: 5%;
    transform: none;
    max-width: 20%;


`
//#endregion