import { useMediaQuery } from '@react-hooks-library/core';
import Ad from 'src/components/ads/Ad';
import ButtonLink from 'src/components/buttonlink/ButtonLink';
import PopularMoviesList from 'src/components/popularmovieslist/PopularMoviesList';
import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';


//#region [Props]
type PopularMoviesRowProps = {
};
//#endregion

//#region [Component]
export default function PopularMoviesRow({ }: PopularMoviesRowProps) {
	const isDesktop = useMediaQuery(theme.mq.desktop);
	return <SPopularMoviesRow>
		<SPosterRow>
			<PopularMoviesList hideHeader />
			<SToProgramButtonLink href='/program'>Se hele kinoprogrammet</SToProgramButtonLink>
		</SPosterRow>
		{isDesktop && <SMpuAd targetId='FW_MPU_forside' canHide={false} />}
	</SPopularMoviesRow>;
}
//#endregion

//#region [Styles]
const SPopularMoviesRow = styled.div`
	grid-area: posterRow;
	display: flex;
	margin-bottom: 35px;
	max-width: 100vw;

	@media ${theme.mq.desktop} {
		margin-bottom: 0;
	}
`;

const SPosterRow = styled.div`
	flex: 1 1 auto;
	margin-right: 35px;
	display: flex;
	flex-direction: column;
	max-width: 100%;

	@media (orientation: portrait) and (${theme.mq.desktopPlain}) {
		margin-bottom: 10px;
	}

	@media ${theme.mq.desktop} {
		max-width: calc(100% - 335px);
		justify-content: center;
	}

	.ItemContainer {
		width: 28%;

		@media (orientation: portrait) and (${theme.mq.desktopPlain}) {
			width: 35%;
		}

		@media (orientation: landscape) and (${theme.mq.desktopPlain}) {
			//@media  (orientation: landscape) {
			width: 15.5%;
		}
	}
`;

const SToProgramButtonLink = styled(ButtonLink)`
	margin-top: 25px;
	align-self: center;

	background: ${theme.palette.buttonGradient2};
	color: ${theme.textColorDark};
	font-size: ${theme.fonts.buttontextL.size};
	font-weight: bold;

	@media (orientation: landscape) and (${theme.mq.desktopPlain}) {
		margin-left: 335px;
	}
`;

const SMpuAd = styled(Ad)`
	margin-left: auto;
	flex: 0 0 300px;
`;
//#endregion