import ButtonLink from "src/components/buttonlink/ButtonLink";
import StreamingPosterRowWithSelfLoad from "src/components/forsiden/StreamingPosterRowWithSelfLoad";
import ExternalImageForUrl from "src/components/images/ExternalImageForUrl";
import PosterRow from 'src/components/posterrow/PosterRow';
import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from 'src/lib/styles/css';
import { IFilmomtale } from 'src/lib/types/Filmomtale';
import { GroupedLayoutObject, IMovieListBlock, IPosterList, ItemType_ListPosterRow, ItemType_PosterList, PublishableDocument } from "src/lib/types/IForsidenV2";
import IMovieList from "src/lib/types/MovieList";


//#region [Props]
type PosterRowWithTitleProps = {
	posterRow: GroupedLayoutObject<IPosterList | IMovieListBlock>;
};
//#endregion

//#region [Component]
export default function PosterRowWithTitleV2({ posterRow }: PosterRowWithTitleProps) {
	if (((posterRow.movies as PublishableDocument[])?.length ?? 0) === 0 && (posterRow.articles?.length ?? 0) === 0) {
		return null;
	}

    const movies = posterRow.movies ?? posterRow.articles;

	const isContentMarketing = !!posterRow.extLogoUrl;

	return <SPosterRowWithTitle>
		<SSectionTitle $isContentMarketing={isContentMarketing}>
			{posterRow.title} {isContentMarketing && <SContentMarketerLogo logoUrl={(posterRow as IMovieListBlock).extLogoUrl!} logoLink={posterRow.extLogoLink as (string | undefined)} />}
		</SSectionTitle>
		{posterRow._type === ItemType_PosterList && <SPosterRow max={6} posterList={movies as IFilmomtale[]} />}
		{posterRow._type === ItemType_ListPosterRow && <>
			<StreamingPosterRowWithSelfLoad max={6} movieList={posterRow.articles![0] as IMovieList} />
			{(posterRow.articles![0] as IMovieList).streamingIds.length > 6 && <SButtonLink href={`/streamingguide/list/${(posterRow.articles![0] as IMovieList).slug.current}`}>Se alle</SButtonLink>}
		</>}
	</SPosterRowWithTitle>;
}
//#endregion

//#region [Component]
type ContentMarketerLogoProps = {
	logoUrl: string;
	logoLink?: string;
	className?: string;
};

function ContentMarketerLogo({ logoUrl, logoLink, className }: ContentMarketerLogoProps) {
	if (logoLink) {
		return <a href={logoLink} rel="noopener" className={className}><ExternalImageForUrl imageUrl={logoUrl} alt="Annonsør" /></a>
	}

	return <ExternalImageForUrl imageUrl={logoUrl} alt="Annonsør" className={className} />
}
//#endregion

//#region [Styles]
const SPosterRowWithTitle = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

type SSectionTitleProps = {
	$isContentMarketing: boolean;
}

const SSectionTitle = styled.h1<SSectionTitleProps>`
	color: white;
	font-size: 30px;
	margin: 0 0 10px 0;
	align-self: flex-start;

	${props => props.$isContentMarketing ? css`
		&::before {
			content: "Annonsørinnhold: ";
			color: ${theme.palette.turkis3};
			display: inline;
		}
	`: ""}
`;

const SPosterRow = styled(PosterRow)`
	.ItemContainer {
		width: 13%;
		max-width: 210px;
	}
`;

const SContentMarketerLogo = styled(ContentMarketerLogo)`
	display: inline-block;
	height: 1em;
	margin-left: 1em;
	vertical-align: middle;
	line-height: 1;

	img {
		display: block;
		height: 100%;
	}
`;

const SButtonLink = styled(ButtonLink)`
	margin-top: 25px;
`;
//#endregion
